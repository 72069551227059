
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import marked from 'marked';

@Component({
	components: {},
})
export default class MarkdownViewer extends Vue {
	@Prop({ default: '' }) public md!: string;
	public get markedDown() {
		return marked(this.md);
	}
}
