
import { Vue, Component } from 'vue-property-decorator';
import PasswordInput from '@/components/inputs/PasswordInput.vue';
import NameInput from '@/components/inputs/NameInput.vue';
import Loading from '@/components/Loading.vue';
import {
	PasswordStrength,
	passwordStrengthRequirement,
} from '@/components/inputs/password.def';
import { EditState } from '@/store/models.def';

import userx from '@/store/modules/userx';
import { Route, RawLocation } from 'vue-router';
import router from '@/router';

import continueToUrl from '@/helpers/continueToUrl';
import { TERMS_VERSION, PRIVACY_POLICY_VERSION } from './termsPolicy/version';

import MarkdownViewer from './termsPolicy/MarkDownViewer.vue';
import termsMd from 'raw-loader!./termsPolicy/terms.md';
import privacyPolicyMd from 'raw-loader!./termsPolicy/privacyPolicy.md';

@Component({
	components: {
		PasswordInput,
		NameInput,
		Loading,
		MarkdownViewer,
	},
})
export default class CompleteRegistration extends Vue {
	public name = '';
	public password = '';
	public repeatPassword = '';
	public loginError = '';
	public validateDisplayNameNow = false;
	public validatePasswordNow = false;
	public passwordStrength = PasswordStrength.Medium;
	public page = 0;

	public saveDisplayNameError: string = '';
	public saveDisplayNameSuccess: string = '';
	public saveDisplayNameState: EditState = 'active';

	public savePasswordError: string = '';
	public savePasswordSuccess: string = '';
	public savePasswordState: EditState = 'active';

	public get title() {
		return this.page === 3
			? 'Terms Of Use'
			: this.page === 4
			? 'Privacy Policy'
			: 'Complete Registration';
	}

	public get saveDisplayNameLabel() {
		const labels = {
			active: 'Save',
			sending: 'Saving...',
			done: 'Saved',
		};
		return labels[this.saveDisplayNameState];
	}
	public get savePasswordLabel() {
		const labels = {
			active: 'Save',
			sending: 'Saving...',
			done: 'Saved',
		};
		return labels[this.savePasswordState];
	}

	public continueUrl = '/';
	get passwordNotSame() {
		if (!this.validatePasswordNow) {
			return false;
		}
		return this.password !== this.repeatPassword;
	}
	get passwordRequirement() {
		return this.passwordNotSame
			? 'Two passwords do not match.'
			: passwordStrengthRequirement[this.passwordStrength];
	}
	get email() {
		return userx.user ? userx.user.email : '';
	}

	public async updateDisplayName() {
		if (this.saveDisplayNameState !== 'active') {
			return;
		}
		this.validateDisplayNameNow = true;
		if (this.name !== null) {
			this.saveDisplayNameState = 'sending';
			const result = await userx.updateDisplayName(this.name);
			if (result.success) {
				this.saveDisplayNameState = 'done';
				this.saveDisplayNameSuccess = 'Success';
				this._next();
			} else {
				this.saveDisplayNameError = result.errorMessage!;
				this.saveDisplayNameState = 'active';
			}
		}
	}
	public async updatePassword() {
		if (this.savePasswordState !== 'active') {
			return;
		}
		this.validatePasswordNow = true;
		if (
			this.password !== null &&
			this.repeatPassword !== null &&
			this.passwordNotSame === false
		) {
			this.savePasswordState = 'sending';
			const result = await userx.updatePassword(this.password);
			if (result.success) {
				this.savePasswordState = 'done';
				this.savePasswordSuccess = 'Success';
				this._next();
			} else {
				this.savePasswordError = result.errorMessage!;
				this.savePasswordState = 'active';
			}
		}
	}

	public get termsMd() {
		return termsMd;
	}
	public get privacyPolicyMd() {
		return privacyPolicyMd;
	}
	public onTermsAgreed() {
		userx.signTerms('terms');
		this._next();
	}
	public onPrivacyPolicyAgreed() {
		userx.signTerms('privacyPolicy');
		this._next();
	}
	public created() {
		this.name = userx.displayName || '';
		this._next();
	}

	public beforeRouteEnter(
		to: Route,
		from: Route,
		next: (
			to?: RawLocation | false | ((vm: CompleteRegistration) => any) | void,
		) => void,
	): void {
		next((vm) => {
			vm.continueUrl = to.query.continueUrl
				? (to.query.continueUrl as string)
				: from.fullPath;
		});
	}
	public beforeRouteUpdate(
		to: Route,
		from: Route,
		next: (
			to?: RawLocation | false | ((vm: CompleteRegistration) => any) | void,
		) => void,
	): void {
		next();
		if (to.query.continueUrl) {
			this.continueUrl = to.query.continueUrl as string;
		}
	}

	private _next() {
		if (!userx.displayName || userx.displayName === '') {
			setTimeout(() => (this.page = 1), this.page === 0 ? 0 : 1000);
		} else if (userx.user!.noPassword) {
			setTimeout(() => (this.page = 2), this.page === 0 ? 0 : 1000);
		} else if (
			!userx.termsSignature ||
			userx.termsSignature.signedVersion < TERMS_VERSION
		) {
			this.page = 3;
		} else if (
			!userx.privacySignature ||
			userx.privacySignature.signedVersion < PRIVACY_POLICY_VERSION
		) {
			this.page = 4;
		} else {
			setTimeout(() => continueToUrl(this.continueUrl), this.page >= 3 ? 0 : 1000);
		}
	}
}
